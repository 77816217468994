import React from 'react'
import { graphql } from 'gatsby'
import styles from './doc.module.css'

import Helmet from 'react-helmet'
import Layout from '../layouts/docs'
import DocumentationNav from '../components/DocumentationNav'

export default ({ data }) => {
  const post = data.markdownRemark
  const frontmatter = post.frontmatter
  return (
    <Layout>
      <Helmet>
        <title>{post.frontmatter.title} | Suggested documentation</title>
        <meta name="description" content={frontmatter.description} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:description" content={frontmatter.description} />
        <meta name="twitter:title" content={frontmatter.title} />
        <meta name="twitter:site" content="@suggestedco" />
        <meta name="twitter:creator" content="@sanjeevan84" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={frontmatter.title} />
        <meta property="og:description" content={frontmatter.description} />
        <meta
          property="og:url"
          content={'https://suggested.co' + frontmatter.slug}
        />
        <meta property="og:site_name" content="Sanj Ambalavanar" />
      </Helmet>
      <div className={styles.root}>
        <div className={styles.nav}>
          <DocumentationNav docs={data.docs.edges} current={post} />
        </div>
        <div className={styles.container}>
          <h1 className={styles.title}>{post.frontmatter.title}</h1>
          <div className={styles.subtitle}>
            Time to read: {post.timeToRead} mins.
          </div>
          <div
            className={styles.content}
            dangerouslySetInnerHTML={{ __html: post.html }}
          />
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
        slug
        thumbnail {
          publicURL
        }
      }
      headings {
        depth
        value
      }
      timeToRead
    }

    docs: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___title] }
      filter: { frontmatter: { type: { eq: "documentation" } } }
    ) {
      edges {
        node {
          frontmatter {
            title
            slug
            type
            description
          }
          html
        }
      }
    }
  }
`
